import React from 'react'
import { Link } from 'gatsby'
import './style.scss'

class Navi extends React.Component {
  render() {
    const { location, title } = this.props
    return (
      <nav className="navbar navbar-expand navbar-dark flex-column flex-md-row bg-primary">
        <div className="container">
          <Link className="text-center" to="/">
            <h1 className="navbar-brand mb-0">{title}</h1>
          </Link>
          <div className="navbar-nav-scroll d-none d-md-block">
            <ul className="navbar-nav bd-navbar-nav flex-row">
              <li className="nav-item">
                <a href="#why" className="nav-link">
                  Wozu Prototyping
                </a>
              </li>
              <li className="nav-item">
                <a href="#how" className="nav-link">
                  Wie loslegen?
                </a>
              </li>
              <li className="nav-item">
                <a href="#process" className="nav-link">
                  Projektablauf
                </a>
              </li>
              <li className="nav-item">
                <a href="#references" className="nav-link">
                  Referenzen
                </a>
              </li>
            </ul>
          </div>
          <div className="navbar-nav flex-row ml-md-auto d-none d-md-flex py-2">
            <a
              href="https://outlook.office365.com/owa/calendar/spacepilots1@spacepilots.co/bookings/s/PLp8DIRnKU-yGxWwo5gRKw2"
              className="btn btn-secondary call-to-action-font w-100 mb-2"
              style={{ whiteSpace: 'nowrap' }}
            >
              Kostenfreies Erstgespräch vereinbaren
            </a>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navi
