import { Link } from 'gatsby'
import React from 'react'
import './style.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faPhone, faGlobe } from '@fortawesome/free-solid-svg-icons'

const Footer = ({ author, title }) => (
  <section className="footer bg-primary text-white">
    <div className="container">
      <div className="row">
        <div className="col-sm-5">
          <p>
            <strong>Agentur für Digital Service Experience</strong>
          </p>
          <p>
            spacepilots ist eine Agentur für Digital Service Experience. Wir
            beraten und unterstützen Geschäftsführer, Innovationsmanager und
            Führungskräfte aus mittelständischen und großen Unternehmen bei der
            Ideen-entwicklung und Bewertung digitaler Lösungen.{' '}
          </p>
          <p>
            Gemeinsam stellen wir mit Ihnen heraus, welche Herausforderungen
            sich Ihre Kunden stellen müssen und wie Ihr Unternehmen von
            digitalen Produkten & Services profitiert.
          </p>
        </div>
        <div className="col-sm-6 offset-sm-1">
          <p>
            <strong>spacepilots</strong> Konzept & Beratung
          </p>
          <p>
            <FontAwesomeIcon icon={faUser} fixedWidth className="mr-2 mt-4" />
            <strong>Christoph Bresler</strong>
          </p>
          <p>
            <FontAwesomeIcon
              icon={faPhone}
              fixedWidth
              flip="both"
              className="mr-2"
            />
            <a href="tel:+4922198652347" className="text-white">
              <strong>+49 221 98652347</strong>
            </a>
          </p>
          <p>
            <FontAwesomeIcon icon={faGlobe} fixedWidth className="mr-2" />
            <a href="http://spacepilots.agency" className="text-white">
              <strong>spacepilots.agency</strong>
            </a>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 text-md-right">
          <h2>spacepilots</h2>
        </div>
      </div>
    </div>
  </section>
)

export default Footer
